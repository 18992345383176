@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  height: 100vh;
  background: #eeeeee;
}

.header {
}

footer {
  height: 160px;
}

.content {
  min-height: calc(100vh - 230px);
}

